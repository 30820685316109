import React, { useState, useRef, useEffect } from 'react'
import { useAccountContext } from 'src/components/account/context'
import { CloseButtonIcon } from 'src/components/Icons/CloseButtonIcon'
import { useMobile } from 'src/hooks/useMobile'
import { useDebounce } from 'src/hooks/useDebounce'
import type { Addresses } from 'src/components/account/types'
import { useToastContext } from 'src/components/ui/ToastMessage/ToastContext'
import { useCheckout } from 'src/sdk/checkout/useCheckout'

import AddressInput from './AddressInput'
import StateSelect from './StateSelect'
import {
  getAddressData,
  cepAutoComplete,
  updateOrRegisterAddress,
  cepCodeMask,
  validadeCep,
} from './utils/addressUtils'
import ButtonLoading from './ButtonLoading'

type AddressFormProps = {
  address?: Addresses
  setIsAddressFormOpen: React.Dispatch<React.SetStateAction<boolean>>
  trackEvent: (eventAction: string, eventPage: string) => void
}

export const AddressForm = ({
  address,
  setIsAddressFormOpen,
  trackEvent,
}: AddressFormProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [cep, setCep] = useState(address?.postal_code ?? '')
  const [cepError, setCepError] = useState(false)
  const debouncedHandleInputChange = useDebounce(handleInputChange, 500)
  const { updateUserDecathlon, userDecathlon, memberAuthToken } =
    useAccountContext()

  const { sendToast } = useToastContext()
  const { orderForm } = useCheckout()

  const formRef = useRef<HTMLFormElement | null>(null)
  const { isMobile } = useMobile()

  const [readOnly, setReadOnly] = useState({
    neighborhood: !!address,
    street: !!address,
    state: !!address,
    city: !!address,
  })

  async function handleSubmit(event: React.FormEvent) {
    event.preventDefault()

    const formStatus = [
      !!formRef?.current?.city.value,
      !!formRef?.current?.neighborhood.value,
      !!formRef?.current?.number.value,
      !!formRef?.current?.postalcode.value,
      !!formRef?.current?.state.value,
      !!formRef?.current?.street.value,
    ]

    if (formStatus.some((status) => status === false)) {
      const hasCep = !!formRef?.current?.postalcode.value
      const cepIsValid = validadeCep(formRef?.current?.postalcode.value)

      setCepError(!(hasCep && cepIsValid))

      return
    }

    try {
      trackEvent('Salvar endereço', 'my account - Gerenciar perfil')
      setIsLoading(true)
      const addressForm = getAddressData({
        newAddress: formRef.current,
        userDecathlon,
        currentAddress: address,
      })

      await updateOrRegisterAddress({
        action: address ? 'update' : 'register',
        orderFormId: orderForm.orderFormId ?? '',
        address: addressForm,
        memberAuthToken,
        userDecathlon,
      })

      await updateUserDecathlon()
      setIsAddressFormOpen(false)
      sendToast({
        text: 'Endereço salvo',
        variant: 'success',
      })

      setIsLoading(false)
    } catch (error) {
      console.error({ error })
      setIsLoading(false)
      setIsAddressFormOpen(false)
      sendToast({
        text: 'Erro ao salvar os dados, tente novamente mais tarde',
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    if (isMobile) {
      return () => {}
    }

    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [isMobile])

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const isValid = validadeCep(e.target.value)

    if (isValid) {
      cepAutoComplete(e, formRef.current, setReadOnly)
    }

    setCepError(!isValid)
  }

  return (
    <div
      className={`flex justify-center items-center w-full h-full ${
        !isMobile
          ? 'fixed top-0 left-0 bg-restructure-background-primary-inverted z-[10000] bg-opacity-80'
          : ''
      }`}
    >
      <div className="form-content flex justify-end h-full w-full">
        <div
          style={{
            scrollbarColor: '#E1E4E7 transparent',
          }}
          className={`bg-white scrollbar-v2 h-full flex flex-col  ${
            isMobile ? 'w-full' : 'w-[491px] overflow-y-auto'
          }`}
        >
          {!isMobile ? (
            <div className="form-header justify-between items-center border-b h-14 w-full border-neutral04 p-6 flex mb-6">
              <span>{address ? 'Editar endereço' : 'Adicionar endereço'}</span>
              <button onClick={() => setIsAddressFormOpen(false)}>
                <CloseButtonIcon />
              </button>
            </div>
          ) : null}

          <div className={`${isMobile ? 'p-0' : 'p-6'} flex flex-1`}>
            <form
              ref={formRef}
              onSubmit={(e) => handleSubmit(e)}
              className="flex flex-1 flex-col justify-between"
              noValidate
            >
              <div className="gap-5 flex flex-col">
                <AddressInput
                  label="CEP"
                  name="postalcode"
                  labelFor="postal-code"
                  value={cep}
                  placeholder="000000-000"
                  maxLength={9}
                  errorMessage={cepError ? 'Preencha o CEP corretamente' : ''}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    debouncedHandleInputChange(e)
                    setCep(cepCodeMask(e.target.value))
                  }}
                />

                <AddressInput
                  label="Rua/Avenida"
                  labelFor="street"
                  name="street"
                  defaultValue={address?.street?.split(',')?.[0]?.trim()}
                  required
                  readOnly={readOnly.street}
                />

                <AddressInput
                  label="Número"
                  labelFor="number"
                  name="number"
                  defaultValue={address?.street?.split(',')?.[1]?.trim()}
                  required
                />

                <AddressInput
                  label="Complemento (Opcional)"
                  labelFor="complement"
                  name="complement"
                  defaultValue={address?.complement}
                />

                <AddressInput
                  label="Bairro"
                  labelFor="neighborhood"
                  name="neighborhood"
                  defaultValue={address?.administrative_divisions?.district}
                  required
                  readOnly={readOnly.neighborhood}
                />

                <div className="flex flex-row gap-5">
                  <AddressInput
                    className={isMobile ? 'w-[60%]' : 'w-full min-w-[50%]'}
                    label="Cidade"
                    labelFor="city"
                    name="city"
                    defaultValue={address?.locality}
                    required
                    readOnly={readOnly.city}
                  />
                  <StateSelect
                    className={isMobile ? 'w-[40%]' : ''}
                    label="Estado"
                    labelFor="state"
                    name="state"
                    defaultValue={address?.administrative_divisions.province}
                    required
                    readOnly={readOnly.state}
                  />
                </div>
              </div>

              <div className="form-actions flex flex-col gap-3 mb-4 mt-12">
                <ButtonLoading
                  type="submit"
                  disabled={isLoading}
                  isLoading={isLoading}
                  label={address ? 'Salvar alterações' : 'Salvar endereço'}
                />
                <ButtonLoading
                  variant="cancel"
                  type="button"
                  onClick={() => {
                    setIsAddressFormOpen(false)
                    trackEvent('Cancelar', 'my account - Gerenciar perfil')
                  }}
                  label="Cancelar"
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

import { useEffect, useState } from 'react'
import LoadingSVG from 'src/components/sections/Account/Loaders/LoadingSVG'
import { useExperiment } from 'statsig-react'

import { CardOrder } from '../../Profile/Orders/CardOrder'
import type { OnlineStoreOrderType, OrderItem } from '../types'

interface OnlineStoreOrdersProps {
  ListOrders: OnlineStoreOrderType
  page: number
  setPage: React.Dispatch<React.SetStateAction<number>>
}

const OnlineStoreOrders = ({
  ListOrders,
  page,
  setPage,
}: OnlineStoreOrdersProps) => {
  const { config } = useExperiment('rastreio_myaccount')
  const [viewLoader, setViewLoader] = useState(false)
  const [filteredOrders, setFilteredOrders] = useState<OrderItem[]>([])

  function removeDuplicates(orders: OrderItem[]) {
    const uniqueItems: { [key: string]: OrderItem } = {}

    orders.forEach((item) => {
      uniqueItems[item.orderId] = item
    })

    return Object.values(uniqueItems)
  }

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 1,
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setPage((prevPage) => prevPage + 1)
        }
      })
    }, observerOptions)

    const target = document.getElementById('observer')

    if (target) {
      observer.observe(target)
    }

    return () => {
      observer.disconnect()
    }
  }, [setPage])

  useEffect(() => {
    if (!ListOrders) {
      return
    }

    setFilteredOrders(removeDuplicates(ListOrders.list))
  }, [ListOrders])

  useEffect(() => {
    setViewLoader(page < ListOrders?.paging?.pages)
  }, [ListOrders.paging.pages, page, setPage])

  return (
    <div className="mt-[24px] sm:mt-[48px]">
      {filteredOrders.map((order, index, array) => (
        <CardOrder
          key={order.orderId}
          order={order}
          index={index + 1}
          length={array.length}
          page={page}
          ShowTracking={Boolean(config.value.show_rastreio)}
        />
      ))}

      {viewLoader && (
        <div className="flex  justify-center  items-center mt-[48px]">
          <div>
            <LoadingSVG style={{ margin: '0', width: '100%' }} />
          </div>
          <span className="ml-[8px] text-[#000000] font-inter  text-[1rem]">
            Carregando mais...
          </span>
        </div>
      )}

      {ListOrders.paging.pages > 1 && <div id="observer" />}
    </div>
  )
}

export default OnlineStoreOrders

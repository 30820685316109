import { useEffect, useState } from 'react'
import { useAccountContext } from 'src/components/account/context'
import Section from 'src/components/sections/Section'
import Button from 'src/components/ui/Button'
import Icon from 'src/components/ui/Icon'
import './styles.scss'
import axios from 'axios'
import type { Toast } from 'src/components/ui/ToastMessage/ToastContext'
import { useToastContext } from 'src/components/ui/ToastMessage/ToastContext'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'
import type { UserDecathlon } from 'src/components/account/types/index'

interface IHandleCheckbox {
  memberAuthToken: string | null
  updateUserDecathlon: () => Promise<void>
  sendToast: (newToast: Toast) => void
  userOptIns: UserOptIns
}

interface UserOptIns {
  'email-5abRusEp'?: boolean
  'sms-bov8K0FR'?: boolean
  'social-f8tNI9LL'?: boolean
  'mysports-RmzeVX4K'?: boolean
  'pushnotif-4fWL4sPx'?: boolean
}

type ToggleSwitchProps = {
  label: string
  isDisabled: boolean
  trackEvent: (action: string, page: string) => void
  setUserOptIns: React.Dispatch<React.SetStateAction<UserOptIns>>
  userOptIns: UserOptIns
  id: keyof UserOptIns
}

export const ToggleSwitch = ({
  label,
  isDisabled,
  trackEvent,
  setUserOptIns,
  userOptIns,
  id,
}: ToggleSwitchProps) => {
  return (
    <div className="flex items-center w-100 border border-solid border-[#E1E4E7] pt-4 pb-2 px-4">
      <label className="relative inline-block w-[58px] h-[34px] min-w-[58px]">
        <input
          className="opacity-0 w-0 h-0"
          type="checkbox"
          disabled={isDisabled}
          defaultChecked={userOptIns[id]}
          checked={userOptIns[id]}
          onChange={(e) => {
            trackEvent(
              `Comunicações ${label} - ${e.target.checked ? 'true' : 'false'}`,
              'my account - Gerenciar perfi'
            )

            if (id === 'mysports-RmzeVX4K') {
              const updatedUserOptIns = Object.keys(userOptIns).reduce(
                (acc, key) => {
                  acc[key as keyof UserOptIns] = e.target.checked

                  return acc
                },
                {} as UserOptIns
              )

              setUserOptIns(updatedUserOptIns)
            } else {
              const updatedUserOptIns = { ...userOptIns }

              updatedUserOptIns[id] = e.target.checked

              const allOtherTogglesFalse = Object.keys(updatedUserOptIns)
                .filter((key) => key !== 'mysports-RmzeVX4K')
                .every((key) => !updatedUserOptIns[key as keyof UserOptIns])

              updatedUserOptIns['mysports-RmzeVX4K'] = !allOtherTogglesFalse

              setUserOptIns(updatedUserOptIns)
            }
          }}
        />
        <span className="slider round" />
      </label>
      <p className="ml-4 text-sm">{label}</p>
    </div>
  )
}

const checkPurpose = (userDecathlon: UserDecathlon | null, id: string) => {
  return userDecathlon?.getConsent?.events?.[0]?.consents?.purposes?.filter(
    (e) => e.id === id
  )?.[0]?.enabled
}

const checkChannel = (userDecathlon: UserDecathlon | null, id: string) => {
  return userDecathlon?.getConsent?.events?.[0]?.consents?.channels?.filter(
    (e) => e.id === id
  )?.[0]?.enabled
}

const handleCheckbox = async ({
  memberAuthToken,
  updateUserDecathlon,
  sendToast,
  userOptIns,
}: IHandleCheckbox) => {
  const data = {
    purposes: [
      {
        id: 'mysports-RmzeVX4K',
        enabled: userOptIns['mysports-RmzeVX4K'],
      },
    ],
    channels: [
      {
        id: 'email-5abRusEp',
        enabled: userOptIns['email-5abRusEp'],
      },
      {
        id: 'sms-bov8K0FR',
        enabled: userOptIns['sms-bov8K0FR'],
      },
      {
        id: 'social-f8tNI9LL',
        enabled: userOptIns['social-f8tNI9LL'],
      },
      {
        id: 'pushnotif-4fWL4sPx',
        enabled: userOptIns['pushnotif-4fWL4sPx'],
      },
    ],
  }

  await axios
    .post('/api/account/updateConsent', {
      memberAuthToken,
      data,
    })
    .then(
      async ({
        data: {
          updateConsent: { status },
        },
      }) => {
        const text =
          status === 'confirmed'
            ? 'Alterações salvas'
            : 'Erro ao salvar os dados, tente novamente mais tarde'

        const variant = status === 'confirmed' ? 'success' : 'error'

        await updateUserDecathlon()

        sendToast({
          text,
          variant,
        })
      }
    )
}

async function updateMissingConsents({
  itensToUpdate,
  memberAuthToken,
  updateUserDecathlon,
  setUserOptIns,
}: {
  itensToUpdate: string[]
  memberAuthToken: string
  updateUserDecathlon: () => Promise<void>
  setUserOptIns: React.Dispatch<React.SetStateAction<UserOptIns>>
}) {
  const data = {
    channels: itensToUpdate.map((id) => ({ id, enabled: true })),
  }

  await axios
    .post('/api/account/updateConsent', {
      memberAuthToken,
      data,
    })
    .then(async () => {
      await updateUserDecathlon()

      setUserOptIns((oldState) => {
        return {
          ...oldState,
          ...itensToUpdate.reduce<UserOptIns>((acc, cur) => {
            acc[cur as keyof UserOptIns] = true

            return acc
          }, {}),
        }
      })
    })
    .catch((err) => console.error(err))
}

export const Communication = () => {
  const [isDisabled, setIsDisabled] = useState(true)
  const buttonText = isDisabled ? 'Editar preferências' : 'Salvar alterações'

  const { userDecathlon, memberAuthToken, updateUserDecathlon } =
    useAccountContext()

  const { sendToast } = useToastContext()

  const [userOptIns, setUserOptIns] = useState<UserOptIns>({
    'email-5abRusEp': checkChannel(userDecathlon, 'email-5abRusEp') ?? false,
    'sms-bov8K0FR': checkChannel(userDecathlon, 'sms-bov8K0FR') ?? false,
    'social-f8tNI9LL': checkChannel(userDecathlon, 'social-f8tNI9LL') ?? false,
    'pushnotif-4fWL4sPx':
      checkChannel(userDecathlon, 'pushnotif-4fWL4sPx') ?? false,
    'mysports-RmzeVX4K':
      checkPurpose(userDecathlon, 'mysports-RmzeVX4K') ?? false,
  })

  function trackEvent(action: string, page: string) {
    const event = makeEventTrack({
      eventAction: action,
      eventPage: page,
    })

    sendEvent(event)
  }

  useEffect(() => {
    const itensToUpdate = [
      'email-5abRusEp',
      'sms-bov8K0FR',
      'social-f8tNI9LL',
      'pushnotif-4fWL4sPx',
    ].filter((id) => checkChannel(userDecathlon, id) === undefined)

    if (itensToUpdate.length > 0 && memberAuthToken) {
      updateMissingConsents({
        setUserOptIns,
        itensToUpdate,
        memberAuthToken,
        updateUserDecathlon,
      })
    }
  }, [userDecathlon, memberAuthToken, updateUserDecathlon])

  return (
    <Section className="mb-[6.5rem] mt-14 text-[#15181B] restructure-small-desktop:mb-14">
      <div className="border-b border-solid border-[#E1E4E7] py-3">
        <Button
          className="flex items-center gap-2 text-sm font-semibold text-primaryBlue"
          icon={
            <Icon
              name={isDisabled ? 'EditPreferences' : 'SavePreferences'}
              width={16}
              height={16}
            />
          }
          iconPosition="right"
          onClick={() => {
            !isDisabled &&
              handleCheckbox({
                memberAuthToken,
                updateUserDecathlon,
                sendToast,
                userOptIns,
              })

            isDisabled &&
              trackEvent(
                'Dados pessoais - Editar informações',
                'my account - Gerenciar perfil'
              )
            setIsDisabled(!isDisabled)
          }}
        >
          {buttonText}
        </Button>
      </div>
      <div className="mt-8 mb-[40px] leading-none">
        <span className="mt-8 mb-[40px] font-inter text-[12px] font-semibold  text-[#101010]">
          Caso feita alguma alteração, as mudanças podem demorar até 24 horas
          para serem refletidas.
        </span>
      </div>
      <div className="mt-8">
        <p className="leading-6 mb-4">Preferências</p>
        <ToggleSwitch
          id="mysports-RmzeVX4K"
          label="Quero receber saldo de cashback, descontos exclusivos, novidades e informações de eventos."
          isDisabled={isDisabled}
          trackEvent={(action, page) => trackEvent(action, page)}
          userOptIns={userOptIns}
          setUserOptIns={setUserOptIns}
        />
      </div>

      <div className="mt-10">
        <p className="leading-6 mb-4">Canais ativos</p>
        <ToggleSwitch
          id="sms-bov8K0FR"
          label="SMS"
          isDisabled={isDisabled}
          trackEvent={(action, page) => trackEvent(action, page)}
          userOptIns={userOptIns}
          setUserOptIns={setUserOptIns}
        />
        <ToggleSwitch
          id="social-f8tNI9LL"
          label="Whatsapp"
          isDisabled={isDisabled}
          trackEvent={(action, page) => trackEvent(action, page)}
          userOptIns={userOptIns}
          setUserOptIns={setUserOptIns}
        />
        <ToggleSwitch
          id="email-5abRusEp"
          label="E-mail"
          isDisabled={isDisabled}
          trackEvent={(action, page) => trackEvent(action, page)}
          userOptIns={userOptIns}
          setUserOptIns={setUserOptIns}
        />
        <ToggleSwitch
          id="pushnotif-4fWL4sPx"
          label="Notificações Aplicativo"
          isDisabled={isDisabled}
          trackEvent={(action, page) => trackEvent(action, page)}
          userOptIns={userOptIns}
          setUserOptIns={setUserOptIns}
        />
      </div>
    </Section>
  )
}

import { Link } from 'gatsby'
import { useLocation } from '@reach/router'
import type { SetStateAction } from 'react'
import { Statsig } from 'statsig-react'
import { ArrowExternalLink } from 'src/components/Icons/ArrowExternalLink'
import { logoutUser } from 'src/utils/account/logoutUser'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'
import { useMobile } from 'src/hooks/useMobile'

import { useAccountContext } from './context'

const BASE_ROUTE = '/account'

const routes = [
  {
    label: 'Home',
    url: BASE_ROUTE,
  },
  {
    label: 'Minhas compras',
    url: `${BASE_ROUTE}/orders`,
  },
  {
    label: 'Meus favoritos',
    url: `${BASE_ROUTE}/wishlist`,
  },
  {
    label: 'Trocas e devoluções',
    url: `${BASE_ROUTE}/exchanges`,
  },
  {
    label: 'Gerenciar perfil',
    url: `${BASE_ROUTE}/profile`,
  },
  {
    label: 'Preciso de ajuda',
    url: `/atendimento`,
  },
]

interface Props {
  onCloseModal?: (value: SetStateAction<boolean>) => void
}

const SideMenu = ({ onCloseModal }: Props) => {
  const closeModal = onCloseModal ? () => onCloseModal(false) : () => {}
  const { pathname } = useLocation()
  const isMobile = useMobile()
  const { memberAuthToken } = useAccountContext()

  function trackEvent(e: React.MouseEvent<HTMLAnchorElement>) {
    const event = makeEventTrack({
      eventAction: e.currentTarget.innerText,
      eventPage: pathname.includes('account') ? 'my account' : pathname,
    })

    sendEvent(event)
  }

  return (
    <aside className="flex flex-col justify-between flex-1 md:flex-none">
      <ul className=" md:[&>*:nth-last-child(2)]:mt-8 text-lg md:text-sm md:mb-8">
        {routes.map(({ label, url }, i) => (
          <li key={i} className="py-3 md:py-1 last:hidden md:last:block">
            <Link
              to={url}
              onClick={(e) => {
                if (label === 'Preciso de ajuda') {
                  Statsig.logEvent('direcionar_atendimento', null, {
                    device: isMobile ? 'mobile' : 'desktop',
                  })
                }

                closeModal()
                trackEvent(e)
              }}
              className="aria-[current=page]:font-bold flex items-center gap-1"
              state={{ prevPath: pathname }}
            >
              {label}
              {!url.includes(BASE_ROUTE) && <ArrowExternalLink />}
            </Link>
          </li>
        ))}

        <button
          className="my-1"
          onClick={() => logoutUser({ memberAuthToken, pathname })}
        >
          Desconectar
        </button>
      </ul>
    </aside>
  )
}

export default SideMenu

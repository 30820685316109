import type { UserDecathlon } from 'src/components/account/types'
import { useContext, useRef, useState } from 'react'
import axios from 'axios'
import * as Yup from 'yup'
import { Input as InputCommon, Select } from 'src/components/common/Input'
import { AccountContext } from 'src/components/account/context'
import Input from 'src/components/ui/Input'
import { Form } from '@unform/web'
import { formatPhoneNumber } from 'src/components/sections/Account/utils/format-phone-number'
import { useToastContext } from 'src/components/ui/ToastMessage/ToastContext'
import { makeEventTrack, sendEvent } from 'src/utils/restructure/analytics'

import CheckSaveIcon from '../../../../images/icons/CheckSaveIcon'
import ClipEditIcon from '../../../../images/icons/ClipEditIcon'
import { PersonalFavoriteStore } from './PersonalFavoriteStore'
import { PersonalFavoriteSport } from './PersonalFavoriteSport'

import './styles.scss'

interface PersonalDataProps {
  userDecathlon: UserDecathlon
}

export const PersonalData = ({ userDecathlon }: PersonalDataProps) => {
  const { memberAuthToken, updateUserDecathlon } = useContext(AccountContext)
  const { sendToast } = useToastContext()
  const [inputEdit, setInputEdit] = useState(false)

  const formRef: any = useRef(null)
  const email = userDecathlon?.getUserProfile.claims.email
  const phoneMobile = userDecathlon?.getUserProfile.claims.phone_number

  const selectOptions = [
    { value: '', label: 'Selecione' },
    { value: 'male', label: 'Masculino' },
    { value: 'female', label: 'Feminino' },
    { value: 'not_known', label: 'Outros' },
  ]

  const formatDate = (type: string, date: string) => {
    if (type === 'display') {
      const [yearDisplay, monthDisplay, dayDisplay] = date.split('-')

      return `${dayDisplay}${monthDisplay}${yearDisplay}`
    }

    const [day, month, year] = date.split('/')

    return `${year}-${month}-${day}`
  }

  const handleSubmit = async (info: any) => {
    try {
      formRef.current.setErrors({})

      const schema = Yup.object().shape({
        mobile: Yup.string().matches(
          /^\+\d{2} \(\d{2}\) 9 \d{4}-\d{4}$/,
          'Número de telefone inválido.'
        ),
        dateBirth: Yup.string()
          .matches(
            /^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
            'Data de nascimento inválida.'
          )
          .default(''),

        document: Yup.string().matches(
          /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
          'O documento deve ter 11 caracteres'
        ),
      })

      if (info.dateBirth === '') {
        delete (schema as any).fields.dateBirth
      }

      if (info.mobile === '') {
        delete (schema as any).fields.mobile
      }

      if (info.document === '') {
        delete (schema as any).fields.document
      }

      await schema.validate(info, {
        abortEarly: false,
      })

      const [day, month, year] = info.dateBirth?.split('/')
      const birthDateFormat = info.dateBirth ? `${year}-${month}-${day}` : ''

      info = {
        given_name: info.name,
        family_name: info.lastname,
        email: userDecathlon?.getUserProfile.claims.email,
        gender: info.gender,
        birthdate: birthDateFormat,
        phone_number: info.mobile
          .replace(' ', '')
          .replace('-', '')
          .replace('(', '')
          .replace(')', ''),
        cpf: info.document
          ? info.document.replace('.', '').replace('-', '')
          : null,
      }

      const response = await axios.post('/api/account/updateUserProfile', {
        memberAuthToken,
        data: info,
      })

      if (response.status === 200) {
        updateUserDecathlon()

        const text = 'Alterações salvas'
        const variant = 'success'

        sendToast({
          text,
          variant,
        })
      }
    } catch (err) {
      const validationErrors: any = {}

      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error: any) => {
          validationErrors[error.path] = error.message
        })
        formRef.current.setErrors(validationErrors)
      }

      const text = 'Erro ao salvar os dados, tente novamente mais tarde'
      const variant = 'error'

      sendToast({
        text,
        variant,
      })
    } finally {
      setInputEdit(false)
    }
  }

  function trackEvent() {
    const event = makeEventTrack({
      eventAction: 'Dados pessoais - Editar informações',
      eventPage: 'my account - Gerenciar perfil',
    })

    sendEvent(event)
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit} className="">
      <div className="mt-16 border-b border-[#E1E4E7] w-full pb-3 items-center mb-8">
        {inputEdit ? (
          <button className="flex items-center gap-2 text-primaryBlue font-inter font-semibold text-sm leading-6">
            <p className="relative  ">Salvar alterações</p>
            <CheckSaveIcon />
          </button>
        ) : (
          <span
            onClick={() => {
              setInputEdit(true)
              trackEvent()
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setInputEdit(true)
                trackEvent()
              }
            }}
            role="button"
            tabIndex={0}
            className="flex items-center gap-2 text-primaryBlue font-inter font-semibold text-sm leading-6"
          >
            <p className="relative  ">Editar informações</p>
            <ClipEditIcon />
          </span>
        )}
      </div>

      <div className="flex items-start flex-col sm:flex-row sm: w-full justify-between xs:justify-around gap-5 sm:gap-10 mb-10 xs:mb-8">
        <InputCommon
          {...{
            classContainer: 'w-full',
            className: `data-input w-full border-neutral04 border-[1px] py-5 px-4 ${
              inputEdit && 'hover:border-[#a4adb7]'
            } mb-4 sm:mb-0 font-inter text-base font-normal`,
            label: 'Primeiro Nome',
            name: 'name',
            defaultValue: userDecathlon?.getUserProfile.claims.given_name,
            background: '#fff',
            disabled: !inputEdit,
          }}
        />
        <InputCommon
          {...{
            classContainer: 'w-full',
            className: `data-input w-full border-neutral04 border-[1px] py-5 px-4 ${
              inputEdit && 'hover:border-[#a4adb7]'
            } mb-4 sm:mb-0 font-inter text-base font-normal`,
            label: 'Sobrenome',
            name: 'lastname',
            defaultValue: userDecathlon?.getUserProfile.claims.family_name,
            background: '#fff',
            disabled: !inputEdit,
          }}
        />
      </div>
      <div className="flex items-start flex-col sm:flex-row sm: w-full justify-between xs:justify-around gap-5 sm:gap-10 mb-10">
        <Input
          {...{
            classContainer: 'w-full',
            className: `data-input w-full border-neutral04 border-[1px] py-5 px-4 ${
              inputEdit && 'hover:border-[#a4adb7]'
            } mb-4 sm:mb-0 font-inter text-base font-normal`,
            label: 'Nascimento',
            name: 'dateBirth',
            defaultValue: userDecathlon?.getUserProfile.claims.birthdate
              ? formatDate(
                  'display',
                  userDecathlon?.getUserProfile.claims.birthdate
                )
              : null,
            background: '#fff',
            mask: '99/99/9999',
            disabled: !inputEdit,
          }}
        />

        <Select
          name="gender"
          label="Gênero"
          defaultValue={userDecathlon?.getUserProfile.claims.gender}
          classContainer="w-full"
          disabled={!inputEdit}
        >
          {selectOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      </div>
      <div className="flex items-start flex-col sm:flex-row sm: w-full justify-between xs:justify-around gap-5 sm:gap-10 mb-10">
        <Input
          {...{
            classContainer: `w-full ${inputEdit ? 'text-[#A4ADB7]' : ''} `,
            className:
              'data-input w-full border-neutral04 border-[1px] py-5 px-4 mb-4 sm:mb-0 font-inter text-base font-normal',
            label: 'E-mail:',
            name: 'email',
            value: email,
            disabled: true,
          }}
        />
        <Input
          {...{
            classContainer: 'w-full',
            className: `data-input w-full border-neutral04 border-[1px] py-5 px-4 ${
              inputEdit && 'hover:border-[#a4adb7]'
            } mb-4 sm:mb-0 font-inter text-base font-normal`,
            label: 'CPF',
            name: 'document',
            placeHolder: '',
            defaultValue: userDecathlon?.getUserProfile.additional_information
              ? userDecathlon?.getUserProfile.additional_information[0].value.replace(
                  /(\d{3})(\d{3})(\d{3})(\d{2})/,
                  '$1.$2.$3-$4'
                )
              : null,
            mask: '999.999.999-99',
            background: '#fff',
            disabled: !inputEdit,
          }}
        />
      </div>
      <div className="flex sm:flex-col sm:flex-row w-full xs:justify-around pb-10 border-b border-[#E1E4E7] mb-10">
        <Input
          {...{
            classContainer: 'w-full sm:pr-[20px]',
            className: `data-input w-full border-neutral04 border-[1px] py-5 px-4 ${
              inputEdit && 'hover:border-[#a4adb7]'
            } mb-4 sm:mb-0 font-inter text-base font-normal`,
            label: 'Telefone de contato',
            name: 'mobile',
            placeHolder: formatPhoneNumber(phoneMobile),
            defaultValue: formatPhoneNumber(phoneMobile?.replace('+55', '')),
            background: '#fff',
            mask: '+55 (99) 9 9999-9999',
            disabled: !inputEdit,
          }}
        />
      </div>

      <PersonalFavoriteStore blockInput={inputEdit} />
      <PersonalFavoriteSport blockInput={inputEdit} />
    </Form>
  )
}

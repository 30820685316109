import { useRef, useState } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import type { Splide as CoreSplide } from '@splidejs/splide'
import { useMobile } from 'src/hooks/useMobile'
import { Link } from 'gatsby'

import Check from '../../../../images/icons/Check'
import ArrowBack from '../../../../images/icons/ArrowBack'
import ArrowNext from '../../../../images/icons/ArrowNext'
import ArrowBackInactive from '../../../../images/icons/ArrowBackInactive'
import ArrowNextInactive from '../../../../images/icons/ArrowNextInactive'

type SliderComponentProps = {
  trackEvent: (trackEvent: string) => void
}

const SliderComponent = ({ trackEvent }: SliderComponentProps) => {
  const { isMobile } = useMobile()
  const [activeSlide, setActiveSlide] = useState(0)
  const splideRef = useRef<CoreSplide | null>(null)

  return (
    <div className="pt-[60px] pr-[20px] pb-[20px] pl-[20px] sm:pt-[40px] sm:pr-[24px] sm:pl-[58px] sm:pb-[36px] border border-[#E1E4E7] rounded-[8px] mt-[-25px]  sm:mt-[0px] sm:-ml-[5px] w-5/5 sm:w-3/5">
      <span className="mr-[4px] mt-[20px] sm:mt-[0px] font-inter text-[#616161] text-[14px] text-base font-normal leading-6 ">
        Você sabia que tem direito a benefícios por ser membro clube? Confira
        alguns
      </span>

      <div className="mt-[40px] h-[267px]">
        <Splide
          options={{
            type: 'fade',
            heightRatio: 0.5,
            pagination: false,
            arrows: false,
            height: '250px',
          }}
          onMoved={(splideObj) => setActiveSlide(splideObj.index)}
          onMounted={(splide: CoreSplide) => (splideRef.current = splide)}
        >
          <SplideSlide>
            <div className="mb-[20px] sm:ml-[0px]">
              <div className="flex gap-[5px] ">
                <div className="w-[25px]">
                  <Check />
                </div>
                <span>
                  Descontos e preços exclusivos em produtos selecionados
                </span>
              </div>

              <div className="flex gap-2">
                <Link
                  to="/collection/?q=923"
                  className="font-inter text-[12px] text-[#3E4751] font-normal leading-5 underline"
                  onClick={() =>
                    trackEvent(
                      'Descontos e preços exclusivos em produtos selecionados - Confira os produtos'
                    )
                  }
                >
                  Confira os produtos
                </Link>

                <Link
                  to="/clube"
                  className="font-inter text-[12px] text-[#3E4751] font-normal leading-5 underline"
                  onClick={() =>
                    trackEvent(
                      'Descontos e preços exclusivos em produtos selecionados - Saiba como funciona'
                    )
                  }
                >
                  Saiba como funciona
                </Link>
              </div>
            </div>

            <div className="mb-[20px] sm:ml-[0px]">
              <div className="flex gap-[5px] ">
                <div className="w-[25px]">
                  <Check />
                </div>
                <span>Dicas de Esporte e convites para eventos exclusivos</span>
              </div>

              <div className="flex gap-2">
                <Link
                  to="/account/profile?tab=tab3"
                  onClick={() =>
                    trackEvent(
                      'Dicas de Esporte e convites para eventos exclusivos - Ative a opção'
                    )
                  }
                  className="font-inter text-[12px] text-[#3E4751] font-normal leading-5 underline"
                >
                  Ative a opção
                </Link>

                <Link
                  to="/clube"
                  onClick={() =>
                    trackEvent(
                      'Dicas de Esporte e convites para eventos exclusivos - Entenda a dinâmica'
                    )
                  }
                  className="font-inter text-[12px] text-[#3E4751] font-normal leading-5 underline"
                >
                  Entenda a dinâmica
                </Link>
              </div>
            </div>

            <div className="mb-[20px] sm:ml-[0px]">
              <div className="flex gap-[5px] ">
                <div className="w-[25px]">
                  <Check />
                </div>
                <span>Desconto de Aniversário</span>
              </div>

              <Link
                to="/account/profile"
                className="font-inter text-[12px] text-[#3E4751] font-normal leading-5 underline"
                onClick={() =>
                  trackEvent(
                    'Desconto de Aniversário - Complete seu cadastro para receber o benefício'
                  )
                }
              >
                Complete seu cadastro para receber o benefício
              </Link>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div className="mb-[15px]">
              <div className="flex gap-[5px] ">
                <div className="w-[25px]">
                  <Check />
                </div>
                <span className="p">
                  Trocas e devoluções em até 45 dias sem cupom fiscal
                </span>
              </div>

              <Link
                to="/servicos/trocas-e-devolucoes"
                onClick={() =>
                  trackEvent(
                    'Trocas e devoluções em até 45 dias sem cupom fiscal - Saiba como funciona'
                  )
                }
                className="font-inter text-[12px] text-[#3E4751] font-normal leading-5 underline"
              >
                Saiba como funciona
              </Link>
            </div>

            <div className="mb-[15px]">
              <div className="flex gap-[5px] ">
                <div className="w-[25px]">
                  <Check />
                </div>
                <span className="p">Serviços exclusivos</span>
              </div>

              <div className="flex gap-2">
                <Link
                  to="/clube/servicos"
                  onClick={() =>
                    trackEvent(
                      'Serviços exclusivos - Encordoamento de raquetes'
                    )
                  }
                  className="font-inter text-[12px] text-[#3E4751] font-normal leading-5 underline"
                >
                  Encordoamento de raquetes
                </Link>

                <Link
                  to="/clube/servicos"
                  onClick={() => {
                    trackEvent('Serviços exclusivos - Serviços de oficina')
                  }}
                  className="font-inter text-[12px] text-[#3E4751] font-normal leading-5 underline"
                >
                  Serviços de oficina
                </Link>
              </div>
            </div>
          </SplideSlide>
        </Splide>
      </div>

      {!isMobile ? (
        <div className="w-full flex  justify-between items-center">
          <div className="flex">
            <div
              className={`${
                activeSlide === 0 ? 'bg-[#15181B]' : 'bg-[#A4ADB7]'
              } w-[52px] h-[3px]  m-0`}
            />

            <div
              className={`${
                activeSlide === 1 ? 'bg-[#15181B]' : 'bg-[#A4ADB7]'
              } w-[52px] h-[3px]  m-0 ml-[5px] `}
            />
          </div>
          <div className="flex gap-4 mr-[-10px]">
            <button
              onClick={() => {
                setActiveSlide(0)
                splideRef?.current?.go(0)
              }}
            >
              {activeSlide === 0 ? <ArrowBackInactive /> : <ArrowBack />}
            </button>
            <button
              onClick={() => {
                setActiveSlide(1)
                splideRef?.current?.go(1)
              }}
            >
              {activeSlide === 1 ? <ArrowNextInactive /> : <ArrowNext />}
            </button>
          </div>
        </div>
      ) : (
        <div className="flex mt-[10px]  items-center">
          <div className="flex  w-full  gap-6">
            <button
              onClick={() => {
                setActiveSlide(0)
                splideRef?.current?.go(0)
              }}
            >
              {activeSlide === 0 ? <ArrowBackInactive /> : <ArrowBack />}
            </button>

            <div className="w-full flex gap-4 items-center">
              <div
                className={`${
                  activeSlide === 0 ? 'bg-[#15181B]' : 'bg-[#A4ADB7]'
                }  w-full h-[3px]  m-0  items-center`}
              />

              <div
                className={`${
                  activeSlide === 1 ? 'bg-[#15181B]' : 'bg-[#A4ADB7]'
                }  w-full h-[3px]  m-0 ml-[5px] `}
              />
            </div>
            <button
              onClick={() => {
                setActiveSlide(1)
                splideRef?.current?.go(1)
              }}
            >
              {activeSlide === 1 ? <ArrowNextInactive /> : <ArrowNext />}
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default SliderComponent

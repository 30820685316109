import { useState } from 'react'
import { EllipsisIcon } from 'src/components/Icons/EllipsisIcon'
import { useMobile } from 'src/hooks/useMobile'
import { useAccountContext } from 'src/components/account/context'
import axios from 'axios'
import { useToastContext } from 'src/components/ui/ToastMessage/ToastContext'
import type { Addresses } from 'src/components/account/types'
import { useCheckout } from 'src/sdk/checkout/useCheckout'

import DeleteConfirmation from './DeleteConfirmation'
import AddressControlsMobile from './AddressControlsMobile'
import { updateOrRegisterAddress } from './utils/addressUtils'

type AddressControlsProps = {
  currentAddress: Addresses
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedAddress: React.Dispatch<React.SetStateAction<string>>

  trackEvent: (eventAction: string, eventPage: string) => void
}

const AddressControls = ({
  setIsModalOpen,
  setSelectedAddress,
  currentAddress,
  trackEvent,
}: AddressControlsProps) => {
  const { memberAuthToken, updateUserDecathlon, userDecathlon } =
    useAccountContext()

  const { orderForm } = useCheckout()

  const { isMobile } = useMobile()
  const { sendToast } = useToastContext()

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [showControlsMobile, setShowControlsMobile] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  function handleEdit() {
    setSelectedAddress(currentAddress.id)
    setIsModalOpen(true)
    setShowControlsMobile(false)
    trackEvent('Editar endereço', 'my account - Gerenciar perfil')
  }

  async function handleDelete() {
    trackEvent('Excluir endereço', 'my account - Gerenciar perfil')
    try {
      setIsLoading(true)
      await axios.post('/api/account/removeAddress', {
        memberAuthToken,
        addressId: currentAddress.id,
      })

      const nextAddress = userDecathlon?.getAddresses.filter(
        ({ id }) => id !== currentAddress.id
      )

      if (currentAddress.is_favorite && nextAddress !== undefined) {
        const newFavorite = {
          ...nextAddress[0],
          is_favorite: true,
        } as Addresses

        await updateOrRegisterAddress({
          action: 'update',
          orderFormId: orderForm.orderFormId ?? '',
          address: newFavorite,
          memberAuthToken,
          userDecathlon,
        })
      }

      await updateUserDecathlon()
      setIsLoading(false)
      sendToast({
        text: 'Endereço excluido',
        variant: 'success',
      })
    } catch (err) {
      console.error(err)
      setIsLoading(false)
      sendToast({
        text: 'Erro ao excluir os dados, tente novamente mais tarde',
        variant: 'error',
      })
    }
  }

  return (
    <div>
      {isMobile ? (
        <div>
          <button onClick={() => setShowControlsMobile(true)}>
            <EllipsisIcon />
          </button>

          {showControlsMobile && !showDeleteConfirmation && (
            <AddressControlsMobile
              handleEdit={() => handleEdit()}
              setShowControlsMobile={setShowControlsMobile}
              setShowDeleteConfirmation={setShowDeleteConfirmation}
              trackEvent={trackEvent}
            />
          )}
        </div>
      ) : (
        <div className="controls-container flex gap-8 self-start relative">
          <button
            aria-label="Editar"
            className="font-inter text-sm font-medium text-primaryBlue"
            onClick={() => handleEdit()}
          >
            Editar
          </button>
          <button
            aria-label="Apagar"
            className="font-inter text-sm font-medium text-restructure-error"
            onClick={() => {
              setShowDeleteConfirmation(true)
              trackEvent('Deletar Endereço', 'my account - Gerenciar perfil')
            }}
          >
            Apagar
          </button>
        </div>
      )}
      {showDeleteConfirmation && (
        <DeleteConfirmation
          setShowDeleteConfirmation={setShowDeleteConfirmation}
          handleDelete={() => handleDelete()}
          isLoading={isLoading}
        />
      )}
    </div>
  )
}

export default AddressControls

import type { OrderItem } from 'src/components/account/Orders/types'
import { useMobile } from 'src/hooks/useMobile'

interface CardOrderProps {
  order: OrderItem
}

export const CardImage = ({ order }: CardOrderProps) => {
  const { isMobile } = useMobile()

  const urlImage = order.items
    .sort((a, b) => b.price - a.price)
    .map((item) => {
      if (isMobile) {
        return item.imageUrl.replace('80-80', '150-150')
      }

      return item.imageUrl
    })

  return (
    <div className="h-full flex items-center justify-center ppp:w-1/2 sm:w-auto">
      <div className="h-full relative flex items-center justify-center ppp:w-[125px] sm:w-[64px] bg-[#fff] opacity-100 rounded-md">
        {order.items.length > 1 && (
          <div className="rounded-tl-[4px] absolute top-0 left-0 bg-[#15181B] text-[#FFFFFF] font-semibold text-[12px] flex items-center justify-center z-10  p-[4px] w-[26px] h-[18px] ">
            +{order.items.length - 1}
          </div>
        )}
        <img
          className="rounded-[4px] mix-blend-multiply"
          src={urlImage[0]}
          alt="Product"
          width={isMobile ? 125 : 64}
          height={isMobile ? 125 : 64}
        />
      </div>
    </div>
  )
}
